/**
 * 产品信息列表
 */
import Vue from "vue";
import { Page } from "iview";
import { pagination as vanPagination } from "vant";
Vue.component("Page", Page);
Vue.component("vanPagination", vanPagination);
import ProductMenu from "@/components/ProductMenu/productMenu.vue";
import { getShopGroupService } from "@/service/spu-group-service";
import { getMarketProductListService } from "@/service/market-service";
import { SOURCE_NAME, sortBy } from "@/service/public/enum";
import { mapMutations, mapState } from "vuex";
import $event, { EVENT_NAME } from "@/utils/event-emitter";
import VueLazyLoad from "vue-lazyload";
import Cookies from "js-cookie";
import { onCharacterPrice, onRandomPlatform, isPhone } from "@/utils/tools";
import { addGoodsCollectionService } from "@/service/goods-collection-service";
import CustomSelect from "@/components/CustomSelect/customSelect.vue";
Vue.use(VueLazyLoad, {
  loading: require("@/assets/Loading/seize-seat-img.jpg")
});
const ProductList = {
  name: "ProductList",
  components: {
    ProductMenu,
    CustomSelect
  },
  computed: {
    ...mapState({
      language: (state) => state.language.code, //获取状态语言
      currencyCode: (state) => state.system.currencyCode, //获取状态管理币种代码
      source: (state) => state.product.source, //获取状态管理来源
      menuId: (state) => state.product.menuId, //获取状态管理菜单id
      menuType: (state) => state.product.menuType, //获取状态管理一级分类
      secondType: (state) => state.product.secondType, //获取状态管理二级分类
      tertiaryType: (state) => state.product.tertiaryType, //获取状态管理三级分类
      levelType: (state) => state.product.levelType, //获取状态管理四级分类
      selectionId: (state) => state.product.selectionId, //获取状态管理选择id
      isNew: (state) => state.product.isNew, //获取状态管理新款
      isSale: (state) => state.product.isSale, //获取状态管理折扣
      isOld: (state) => state.product.isOld, //获取状态管理古着
      label: (state) => state.product.label, //获取状态管理标签
      level: (state) => state.product.level, //获取状态管理标签
      isParent: (state) => state.product.isParent, //获取状态管理是否是子级
      showMenu: (state) => state.product.showListFilter //获取状态管理是否是展示商品筛选界面
    }),
    onStyle() {
      return (key, isBanner) => {
        if (key === 0 || key === 1 || key === 2 || key === 3) {
          return "margin-top";
        }
        if (isBanner === 1) {
          return "advert-li";
        }
      };
    }
  },
  data() {
    return {
      onCharacterPrice: onCharacterPrice,
      loadingVisible: false,
      sortVisible: false, //排序弹框
      selectSort: {}, //选中的排序
      sortList: sortBy, //排序数据集
      productList: {}, //商品列表对象
      productSelect: "", //选中的商品
      productType: [], //商品属性对象
      productClassify: [], //商品分类对象
      productBrands: [], //商品品牌对象
      productNewIn: [], //商品折扣新款古着
      productSize: [], //商品尺码
      sizeName: "", //尺码名称
      barStyle: "",
      menuStyle: "",
      scrollNumber: 0,
      i: 0,
      productParams: {
        classifySelect: "", //选择分类
        brandsSelect: "", //选择品牌
        attributeIds: "", //选择的属性
        specsIds: "", //选择的尺码
        prices: [], //选择的价格
        currentPage: 1,
        pageSize: 70
      },
      queryObj: {},
      isPhone: isPhone
    };
  },
  created() {
    if (
      this.$route.query.menuType === undefined ||
      this.$route.query.secondId === undefined ||
      this.$route.query.page === undefined
    ) {
      this.$router.push("/pre");
      return;
    }
    this.queryObj = this.$route.query;
    if (this.$route.query.source === "1ab64q2m3bnr916ty8") {
      if (this.$route.query.label !== undefined) {
        this.SET_LABEL(this.$route.query.label);
      }
      if (this.$route.query.id !== undefined) {
        this.SET_SELECTION_ID(this.$route.query.id);
      }
      if (this.$route.query.cid1 !== undefined) {
        this.SET_MENU_ID(this.$route.query.cid1);
      }
      this.SET_SOURCE(SOURCE_NAME.SUPER_SALE);
      this.SET_SECOND_TYPE({
        categoryId: "",
        name: ""
      });
      this.SET_TERTIARY_TYPE({
        categoryId: "",
        name: ""
      });
    }
    this.selectSort = {
      id: "",
      name: this.$t("list.sortBy")
    };
    // this.onProductType()
    /**
     * 订阅商品菜单事件
     */
    $event.$on(EVENT_NAME.PRODUCT_MENU_QUERY, () => {
      this.productType = [];
    });
    /**
     * 订阅商品列表事件名
     */
    $event.$on(EVENT_NAME.PRODUCT_LIST_QUERY, () => {
      setTimeout(() => {
        this.queryObj = this.$route.query;
        // this.onProductType()
        this.productType = [];
        this.productClassify = [];
        this.productBrands = [];
        this.productNewIn = [];
        this.productSize = [];
        this.productParams.prices = [];
        this.loadingVisible = true;
        this.productParams.currentPage = 1;
        this.onProductService();
        document.documentElement.scrollTop = document.body.scrollTop = 0; //定位滚动条到头部
      });
    });
    this.loadingVisible = true;
    this.onProductService();
    // !this.productType.includes(this.threeCategory.id)?this.productType.push(this.threeCategory):null
    // this.productType.type = this.threeCategory
  },
  mounted() {
    this.menuStyle = "height:" + (window.innerHeight - 149) + "px";
    window.addEventListener("scroll", this.handleScroll);
    document.documentElement.scrollTop = document.body.scrollTop = 0; //定位滚动条到头部
    // window.onresize = () => {
    //     return (() => {
    //         this.menuStyle = 'height:'+(window.innerHeight-149)+'px';
    //     })()
    // };
    window.addEventListener("resize", this.menuStyleFun, true);
  },
  methods: {
    ...mapMutations("product", [
      "SET_PRODUCT_ID",
      "SET_IS_NEW",
      "SET_IS_SALE",
      "SET_IS_OLD",
      "SET_MENU_TYPE",
      "SET_MENU_ID",
      "SET_LABEL",
      "SET_SELECTION_ID",
      "SET_SOURCE",
      "SET_SECOND_TYPE",
      "SET_TERTIARY_TYPE",
      "SET_LIST_FILTER"
    ]),
    menuStyleFun() {
      this.menuStyle = "height:" + (window.innerHeight - 149) + "px";
    },
    handleScroll() {
      this.scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      if (this.scrollTop > 0) {
        this.barStyle = "top:104px; position: sticky;";
      } else {
        this.barStyle = "top:0; position: relative;";
      }
      //
      // let scroll = this.scrollTop - this.i
      // this.i = this.scrollTop
      //
      // let sliceContainer = document.getElementById('slice-container').offsetTop
      //
      //
      // if(scroll<0){
      //     // let menuHeight = document.getElementById('menuHeight')
      //     let productListTop = document.getElementById('productList').offsetTop
      //     console.log(productListTop)
      //     this.menuStyle = 'top:'+this.scrollTop+'px; position: relative;'
      // }else{
      //     let menuHeight = document.getElementById('menuHeight')
      //     let productListTop = document.getElementById('productList').offsetTop
      //     if(menuHeight.offsetHeight>window.innerHeight-149 ){
      //         if(this.scrollTop+window.innerHeight-149 > menuHeight.offsetHeight){
      //             this.menuStyle = 'top:'+(window.innerHeight-menuHeight.offsetHeight)+'px; position: sticky;'
      //         }else{
      //             this.menuStyle = 'top:-'+(productListTop-149)+'px; position: relative;'
      //             this.scrollNumber = this.scrollTop
      //             console.log(sliceContainer)
      //         }
      //     }else{
      //         this.menuStyle = 'top:149px; position: sticky;'
      //     }
      // }
    },
    /**
     * 清空
     */
    onClearFilter () {
      this.loadingVisible = true;
      this.productType = []
      this.productClassify = []
      this.productBrands = []
      this.productSize = []
      this.productNewIn = []
      this.productParams.prices = [];
      this.onProductService();

    },
    /**
     * 排序
     * @param obj
     */
    onSort(obj) {
      this.selectSort = obj;
      this.sortVisible = false;
      this.loadingVisible = true;
      this.onProductService();
    },
    /**
     * 删除属性筛选条件
     * @param id
     */
    onCloseScreen(id) {
      this.loadingVisible = true;
      for (let i in this.productType) {
        if (id === this.productType[i].id) {
          this.productType.splice(i, 1);
        }
      }
      let obj = {
        id: id,
        name: ""
      };
      $event.$emit(EVENT_NAME.COMMODITY_ATTRIBUTE_QUERY, obj);
      this.onProductService();
    },
    /**
     * 删除分类筛选条件
     * @param id
     */
    onCloseClassify(id) {
      this.loadingVisible = true;
      for (let i in this.productClassify) {
        if (id === this.productClassify[i].id) {
          this.productClassify.splice(i, 1);
        }
      }
      let obj = {
        id: id,
        name: this.$t("menu.category")
      };
      $event.$emit(EVENT_NAME.COMMODITY_ATTRIBUTE_QUERY, obj);
      this.onProductService();
    },
    /**
     * 删除品牌筛选条件
     * @param id
     */
    onCloseBrands(id) {
      this.loadingVisible = true;
      for (let i in this.productBrands) {
        if (id === this.productBrands[i].id) {
          this.productBrands.splice(i, 1);
        }
      }
      let obj = {
        id: id,
        name: this.$t("menu.brands")
      };
      $event.$emit(EVENT_NAME.COMMODITY_ATTRIBUTE_QUERY, obj);
      this.onProductService();
    },
    /**
     * 删除尺码
     * @param id
     */
    onCloseSize(id) {
      this.loadingVisible = true;
      for (let i in this.productSize) {
        if (id === this.productSize[i].id) {
          this.productSize.splice(i, 1);
        }
      }
      let obj = {
        id: id,
        name: this.$t("menu.size")
      };
      $event.$emit(EVENT_NAME.COMMODITY_ATTRIBUTE_QUERY, obj);
      this.onProductService();
    },
    /**
     * 删除折扣新款古着
     * @param id
     */
    onCloseNewIn(id) {
      this.loadingVisible = true;
      for (let i in this.productNewIn) {
        if (id === this.productNewIn[i].id) {
          this.productNewIn.splice(i, 1);
        }
      }
      let obj = {
        id: id,
        name: this.$t("menu.newIn")
      };
      if (id === 407 || id === 411) {
        this.SET_IS_NEW(0);
      }
      if (id === 408 || id === 412) {
        this.SET_IS_SALE(0);
      }
      if (id === 409) {
        this.SET_IS_OLD(0);
      }
      $event.$emit(EVENT_NAME.COMMODITY_ATTRIBUTE_QUERY, obj);
      this.onProductService();
    },
    /**
     * 删除价格筛选条件
     * @param id
     */
    onClosePrice() {
      this.loadingVisible = true;
      this.productParams.prices = [];
      this.onProductService();
    },
    /**
     * 属性筛选条件
     */
    onSelectAttribute(obj) {
      this.loadingVisible = true;
      if (this.productType.length === 0) {
        this.productType.push(obj);
      } else {
        let index = this.productType.findIndex((val) => val.id === obj.id);
        if (this.productType.findIndex((val) => val.id === obj.id) !== -1) {
          this.productType.splice(index, 1);
        } else {
          this.productType.push(obj);
        }
      }
      this.onProductService();
    },
    /**
     * 分类筛选条件
     */
    onSelectType(obj) {
      this.loadingVisible = true;
      if (this.productClassify.length === 0) {
        this.productClassify.push(obj);
      } else {
        let index = this.productClassify.findIndex((val) => val.id === obj.id);
        if (this.productClassify.findIndex((val) => val.id === obj.id) !== -1) {
          this.productClassify.splice(index, 1);
        } else {
          this.productClassify.push(obj);
        }
      }
      this.onProductService();
    },
    /**
     * 品牌筛选条件
     * @param obj
     */
    onSelectBrands(obj) {
      this.loadingVisible = true;
      if (this.productBrands.length === 0) {
        this.productBrands.push(obj);
      } else {
        let index = this.productBrands.findIndex((val) => val.id === obj.id);
        if (this.productBrands.findIndex((val) => val.id === obj.id) !== -1) {
          this.productBrands.splice(index, 1);
        } else {
          this.productBrands.push(obj);
        }
      }
      this.onProductService();
    },
    /**
     * 新款古着折扣条件筛选
     * @param obj
     */
    onSelectNewIn(obj) {
      this.loadingVisible = true;
      if (this.productNewIn.length === 0) {
        this.productNewIn.push(obj);
      } else {
        let index = this.productNewIn.findIndex((val) => val.id === obj.id);
        if (this.productNewIn.findIndex((val) => val.id === obj.id) !== -1) {
          this.productNewIn.splice(index, 1);
        } else {
          this.productNewIn.push(obj);
        }
      }

      this.onProductService();
    },
    /**
     * 尺码条件筛选
     * @param obj
     */
    onSelectSize(obj) {
      this.loadingVisible = true;
      let list = obj.obj;
      this.sizeName = obj.name;
      if (this.productSize.length === 0) {
        this.productSize.push(list);
      } else {
        let index = this.productSize.findIndex((val) => val.id === list.id);
        if (this.productSize.findIndex((val) => val.id === list.id) !== -1) {
          this.productSize.splice(index, 1);
        } else {
          this.productSize.push(list);
        }
      }
      this.onProductService();
    },
    /**
     * 价格
     * @param price
     */
    onPrice(price) {
      this.loadingVisible = true;
      this.productParams.prices = price;
      this.onProductService();
    },
    /**
     * 根据来源调用不同商品数据
     */
    onProductService() {
      switch (this.source) {
        case SOURCE_NAME.SUPER_SALE: //超卖
          this.onMarketProductList().then();
          break;
        case SOURCE_NAME.MENU_ADVERT: //广告
          this.onMarketProductList().then();
          break;
        case SOURCE_NAME.MENU_LINK_LIST: //header下拉菜单
          this.onProductList().then();
          break;
      }
    },
    /**
     * 点击喜爱
     */
    onLike(obj) {
      if (!Cookies.get("token")) {
        $event.$emit(EVENT_NAME.HEADER_TIPS_STATUS);
        return;
      }
      this.onGoodsCollection(obj.id, obj.isWish).then();
    },
    /**
     * 跳转到详情页
     * @param id
     */
    onQueryDetails(id) {
      this.SET_PRODUCT_ID(id);
      this.$router.push("/product/details?id=" + id);
    },
    /**
     * 广告跳转
     * @param obj
     */
    onAdvertLink(obj) {
      window.open(obj.bannerDto.link, "_blank");
    },
    /**
     * 发送当前页
     * @param number
     */
    onSearchPage(number) {
      this.loadingVisible = true;
      this.productParams.currentPage = number;
      this.queryObj.page = number;
      this.$router.push(
        "/product/list?menuType=" +
          this.queryObj.menuType +
          "&secondId=" +
          this.queryObj.secondId +
          "&secondName=" +
          this.queryObj.secondName +
          "&tertiaryId=" +
          this.queryObj.tertiaryId +
          "&tertiaryName=" +
          this.queryObj.tertiaryName +
          "&label=" +
          this.queryObj.label +
          "&page=" +
          number +
          "&t=" +
          onRandomPlatform()
      );
      this.onProductService();
      document.documentElement.scrollTop = document.body.scrollTop = 0; //定位滚动条到头部
    },
    /**
     * 发送每页显示的条数
     * @param number
     */
    onSearchPageSize(number) {
      this.loadingVisible = true;
      this.productParams.pageSize = number;
      this.queryObj.size = number;
      this.onProductService();
    },
    /**
     * 拉取特卖商品列表
     * @returns {Promise<void>}
     */
    async onProductList() {
      try {
        if (this.loadingVisible) {
          this.productList = {};
        }
        this.productParams.attributeIds = "";
        for (let i in this.productType) {
          this.productParams.attributeIds += this.productType[i].id + ",";
        }
        this.productParams.classifySelect = "";
        for (let i in this.productClassify) {
          this.productParams.classifySelect += this.productClassify[i].id + ",";
        }
        this.productParams.brandsSelect = "";
        for (let i in this.productBrands) {
          this.productParams.brandsSelect += this.productBrands[i].id + ",";
        }
        this.productParams.specsIds = "";
        for (let i in this.productSize) {
          this.productParams.specsIds += this.productSize[i].id + ",";
        }
        let params = {
          brandIds: this.productParams.brandsSelect,
          attributeIds: this.productParams.attributeIds,
          price2:
            this.productParams.prices.length !== 0
              ? this.productParams.prices[0] +
                "," +
                this.productParams.prices[1]
              : "",
          specs: this.productParams.specsIds,
          cid1: this.$route.query.menuType,
          cid2: "",
          cid3: "",
          cid4: "",
          cid5: "",
          isSale: this.isSale,
          isNew: this.isNew,
          isOld: this.isOld,
          sortBy: this.selectSort.id,
          size: 70,
          page: this.queryObj.page,
          status: 0
        };
        params.cid2 = this.$route.query.secondId;
        if (this.level == 2) {
          params.cid2 = this.$route.query.secondId;
        }
        params.cid3 = this.$route.query.tertiaryId;
        if (this.level == 3) {
          if (this.isParent === 0) {
            params.cid3 = this.productParams.classifySelect;
          }
        }
        if (this.level == 4) {
          params.cid4 = this.levelType.categoryId;
          if (this.isParent === 0) {
            params.cid4 = this.productParams.classifySelect;
          }
        }
        if (this.level == 5) {
          if (this.isParent === 0) {
            params.cid5 = this.productParams.classifySelect;
          } else {
            params.cid5 = this.menuId;
          }
        }
        const data = await getShopGroupService(params);
        this.productList = data;
        this.loadingVisible = false;
      } catch (error) {
        this.loadingVisible = false;
        console.log(error);
      }
    },
    /**
     * 查询市场活动分组商品列表
     * @returns {Promise<void>}
     */
    async onMarketProductList() {
      try {
        this.loadingVisible = true;
        this.productList = {};
        this.productParams.attributeIds = "";
        for (let i in this.productType) {
          this.productParams.attributeIds += this.productType[i].id + ",";
        }
        this.productParams.classifySelect = "";
        for (let i in this.productClassify) {
          this.productParams.classifySelect += this.productClassify[i].id + ",";
        }
        this.productParams.brandsSelect = "";
        for (let i in this.productBrands) {
          this.productParams.brandsSelect += this.productBrands[i].id + ",";
        }
        this.productParams.specsIds = "";
        for (let i in this.productSize) {
          this.productParams.specsIds += this.productSize[i].id + ",";
        }
        let params = {
          brandIds: this.productParams.brandsSelect,
          attributeIds: this.productParams.attributeIds,
          price2:
            this.productParams.prices.length !== 0
              ? this.productParams.prices[0] +
                "," +
                this.productParams.prices[1]
              : "",
          specs: this.productParams.specsIds,
          id: this.selectionId,
          cid1: this.$route.query.menuType,
          cid2: this.$route.query.secondId,
          cid3: this.$route.query.tertiaryId,
          cid4: "",
          cid5: "",
          isSale: this.isSale || 0,
          isNew: this.isNew || 0,
          isOld: this.isOld || 0,
          sortBy: this.selectSort.id,
          size: 70,
          page: this.queryObj.page,
          status: 0
        };
        const data = await getMarketProductListService(params);
        this.productList = data;
        this.loadingVisible = false;
      } catch (error) {
        this.loadingVisible = false;
        console.log(error);
      }
    },
    /**
     * 新增商品收藏
     * @returns {Promise<void>}
     */
    async onGoodsCollection(id, type) {
      try {
        // this.loadingVisible = true
        // this.productList = {}
        let params = {
          productId: id,
          type: type === 0 ? 1 : 0
        };
        const { code } = await addGoodsCollectionService(params);
        if (code === 1) {
          for (let i in this.productList.content) {
            if (id === this.productList.content[i].id) {
              //isWish
              type === 0
                ? (this.productList.content[i].isWish = 1)
                : (this.productList.content[i].isWish = 0);
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        if (this.$route.query.page) {
          this.page = this.$route.query.page;
        }
      }
    }
  },
  /**
   * 销毁事件
   */
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
    $event.$off([EVENT_NAME.PRODUCT_LIST_QUERY, EVENT_NAME.PRODUCT_MENU_QUERY]);
    this.SET_LIST_FILTER(false)
  }
};
export default ProductList;
